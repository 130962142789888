<template>
  <div class="privacy-policy">
    <h1 class="title">
      POLÍTICA DE PRIVACIDADE
    </h1>
    <p>
      Esta Política de Privacidade descreve como suas informações pessoais são
      coletadas, usadas e compartilhadas quando você utiliza o <span class="bold">Aplicativo Davi
        Esmael</span> (doravante “App”).
    </p>
    <h2 class="subtitle">
      INFORMAÇÕES PESSOAIS QUE COLETAMOS
    </h2>
    <p>
      Quando você usa o App, coletamos automaticamente certas informações sobre seu dispositivo, incluindo:
    </p>
    <ul>
      <li>
        - Navegador do dispositivo
      </li>
      <li>
        - Endereço IP
      </li>
      <li>
        - Fuso horário
      </li>
      <li>
        - Alguns cookies instalados no dispositivo
      </li>
    </ul>
    <p>
      Além disso, conforme você navega no App, coletamos informações sobre:
    </p>
    <ul>
      <li>
        - Páginas individuais acessadas
      </li>
      <li>
        - Como você interage com o App
      </li>
    </ul>
    <p>
      Essas informações coletadas automaticamente são referidas como “Informações do Dispositivo”.
    </p>
    <p>
      Coletamos Informações do Dispositivo utilizando as seguintes técnicas:
    </p>
    <ul>
      <li>
        <span class="bold">- Cookies:</span> Arquivos de dados armazenados no seu dispositivo,
        geralmente com um identificador único anônimo. Para mais informações sobre cookies e como
        desabilitá-los, visite
        <a
          href="http://www.allaboutcookies.org"
          target="_blank"
          rel="noopener noreferrer"
        >All About Cookies</a>
        .
      </li>
      <li>
        <span class="bold">- Arquivos de Log:</span> Registram ações no App e coletam dados como endereço IP,
        tipo de navegador, provedor de Internet, páginas de entrada/saída e data/hora de uso.
      </li>
      <li>
        <span class="bold">- Web Beacons, Tags e Pixels:</span> Arquivos eletrônicos usados para registrar
        informações sobre como você navega no App.
      </li>
    </ul>
    <p>
      Os dados pessoais dos usuários recolhidos incluem:
    </p>
    <ul>
      <li>
        - Nome completo
      </li>
      <li>
        - E-mail
      </li>
      <li>
        - Dados para otimização da navegação: páginas acessadas, palavras-chave utilizadas na busca,
        endereço IP e MAC.
      </li>
    </ul>

    <h2 class="subtitle">
      FINALIDADES DOS DADOS PESSOAIS DOS USUÁRIOS
    </h2>
    <p>Utilizamos seus dados pessoais para:</p>
    <ul>
      <li>
        - Comunicar com você e mantê-lo atualizado sobre o progresso de sua requisição.
      </li>
      <li>
        - Prevenir contra potenciais riscos e fraudes (em particular, seu endereço IP).
      </li>
      <li>
        - Melhorar e otimizar nosso App (gerando análises estatísticas sobre como nossos usuários navegam
        e interagem com o App).
      </li>
    </ul>

    <p>
      Os dados coletados não serão repassados a terceiros, exceto em casos de convênios entre agências
      estaduais ou federais, prestação de contas ou por ordens judiciais. Os dados pessoais coletados só
      poderão ser excluídos após o prazo legal estipulado por cada programa em que o titular esteja participando.
    </p>

    <h2 class="subtitle">
      COMPARTILHAMENTO DOS DADOS
    </h2>
    <p>
      Compartilhamos dados apenas quando obrigados por lei ou quando solicitado por outras esferas do poder
      público através de convênios.
    </p>

    <h2 class="subtitle">
      TRANSFERÊNCIA DE DADOS A TERCEIROS
    </h2>
    <p>
      No caso de transferência internacional, deverá haver contrato com empresas sediadas em países que possuam
      Lei de Privacidade de Dados. Ao acessar nossos serviços e aceitar nossa política, você consente com o
      processamento, transferência e armazenamento de suas informações neste ou em outros países, desde que
      amparados por legislação de proteção e privacidade de dados pessoais.
    </p>

    <h2 class="subtitle">
      POLÍTICA DE COOKIES OU DADOS DE NAVEGAÇÃO
    </h2>
    <p>
      Cookies são arquivos de texto enviados ao computador do usuário e nele armazenados, com informações relacionadas
      à navegação no site. Estas informações incluem:
    </p>
    <ul>
      <li>
        - Dados de acesso (local e horário de acesso, IP, MAC Address)
      </li>
    </ul>

    <p>
      Esses dados são armazenados pelo navegador ou App para garantir a legitimidade dos dados e atender à
      LEI 12.965 de 23 de abril de 2014.
    </p>
    <p>Após o usuário aceitar, utilizamos um sistema de coleta de dados de navegação por meio de cookies.</p>
    <ul>
      <li>
        <span class="bold">- Cookies persistentes:</span> Permanecem no disco rígido do usuário após o navegador
        ser fechado e são usados em visitas subsequentes ao site. Podem ser removidos seguindo as instruções do
        seu navegador.
      </li>
      <li>
        <span class="bold">- Cookies de sessão:</span> Temporários e desaparecem após o navegador ser fechado.
      </li>
    </ul>

    <p>
      É possível redefinir seu navegador para recusar todos os cookies, mas isso pode impedir a ativação da sessão.
    </p>

    <h2 class="subtitle">
      CONSENTIMENTO
    </h2>
    <p>
      Ao realizar o cadastro, o titular consente com esta Política de Privacidade. O usuário tem direito à
      titularidade de seus dados pessoais (Artigo 17) e pode retirar seu consentimento a qualquer momento,
      além de exercer os seguintes direitos:
    </p>
    <ul>
      <li>
        - Confirmação da existência de tratamento;
      </li>
      <li>
        - Acesso aos dados mantidos pelo controlador;
      </li>
      <li>
        - Correção de dados incompletos, inexatos ou desatualizados;
      </li>
      <li>
        - Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade
        com a LGPD;
      </li>
      <li>
        - Portabilidade de seus dados pessoais a outro fornecedor de serviço;
      </li>
      <li>
        - Eliminação dos dados pessoais quando retirado o consentimento dado anteriormente;
      </li>
      <li>
        - Relação de com quem seus dados foram compartilhados;
      </li>
      <li>
        - Informação de que poderá negar consentimento e quais suas consequências.
      </li>
    </ul>

    <p>
      Quando uma decisão sobre seus dados pessoais é tomada com base em tratamento automatizado, o titular tem direito
      à revisão dessa decisão (Artigo 20). Disponibilizamos canal de atendimento pelo e-mail
      <a href="mailto:dpocities@gmail.com">dpocities@gmail.com</a>. Ainda
      assiste à pessoa física o direito de peticionar contra os agentes de tratamento diretamente à Autoridade Nacional
      de Proteção de Dados, que exerce fiscalização e controle sobre aqueles (Artigo 18, §1º).
    </p>

    <h2 class="subtitle">
      MODIFICAÇÕES DA POLÍTICA DE PRIVACIDADE
    </h2>
    <p>
      O proprietário se reserva o direito de fazer alterações nesta política de privacidade a qualquer momento,
      informando o titular através de notificação no site, e-mail ou push do aplicativo.
    </p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicyEsmael',
  title: 'Davi Esmael - Política de Privacidade'
};
</script>

<style lang="scss" scoped>
.privacy-policy {
  max-width: 100vw;
  padding: 1rem;

  .title {
    font-size: 24px;
  }

  .subtitle {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .wrap {
    padding: 0 2rem;
  }

  h1 {
    font-weight: bold;
    margin-bottom: 2rem;
  }

  p,
  ul {
    margin-bottom: 2rem;
  }

  li {
    margin-bottom: 1rem;
  }

  .font-gray {
    color: $gray-500;
  }

  .text-center {
    text-align: center;
  }

  a {
    color: darkblue;
    text-decoration: underline;
    cursor: pointer;
  }

  .bold {
    font-weight: $font-bold;
  }
}
</style>
